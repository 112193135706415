<template>
  <v-container fluid>
    <v-snackbar :value="isError" color="error">
      {{ isError ? getError.errorMessage : '' }}
      <v-btn color="white" text @click="setError({ isError: false, error: null})">Cerrar</v-btn>
    </v-snackbar>
    <v-snackbar :value="infoSnackbar" color="success">
      {{ infoSnackbar ? infoSnackbarMessage : '' }}
      <v-btn color="white" text @click="infoSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <v-dialog v-model="showNuevoClienteDialog" width="500px">
      <v-card>
        <v-card-title>Crear Nuevo Cliente</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-layout>
            <v-row>
              <v-col cols="12" sm="12" class="pb-0">
                <v-text-field
                  outlined
                  dense
                  label="Nombre del Cliente"
                  v-model="nuevoClienteModel.nombreCliente"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Dirección del Cliente"
                  v-model="nuevoClienteModel.direccion"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Contacto del Cliente"
                  v-model="nuevoClienteModel.contacto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="pt-0">
                <v-text-field
                  outlined
                  dense
                  label="Precio de Balón 10k"
                  prefix="S/"
                  v-model="nuevoClienteModel.precio10k"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="pt-0">
                <v-text-field
                  outlined
                  dense
                  label="Precio de Balón 45k"
                  prefix="S/"
                  v-model="nuevoClienteModel.precio45k"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="showNuevoClienteDialog = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green darken-1"
            :loading="isLoading"
            :disabled="isLoading"
            @click="crearCliente"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEditarClienteDialog" width="500px">
      <v-card>
        <v-card-title>Editar Cliente</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-layout>
            <v-row>
              <v-col cols="12" sm="12" class="pb-0">
                <v-text-field
                  outlined
                  dense
                  label="Nombre del Cliente"
                  v-model="clienteSeleccionadoModel.nombreCliente"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Dirección del Cliente"
                  v-model="clienteSeleccionadoModel.direccion"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Contacto del Cliente"
                  v-model="clienteSeleccionadoModel.contacto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="pt-0">
                <v-text-field
                  outlined
                  dense
                  label="Precio de Balón 10k"
                  prefix="S/"
                  v-model="clienteSeleccionadoModel.precio10k"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="pt-0">
                <v-text-field
                  outlined
                  dense
                  label="Precio de Balón 45k"
                  prefix="S/"
                  v-model="clienteSeleccionadoModel.precio45k"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="showEditarClienteDialog = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green darken-1"
            :loading="isLoading"
            :disabled="isLoading"
            @click="editarCliente"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col>
        <v-data-table
          fixed-header
          :headers="headers"
          :items="getListaClientes"
          :loading="isLoading"
          loading-text="Cargando... Espera un Momento"
          no-data-text="No hay datos para mostrar"
          :search="buscarTabla"
          item-key="idCliente"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <div>
                <v-toolbar-title>CLIENTES</v-toolbar-title>
              </div>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn icon color="success" class="mx-2" @click="actualizarTabla">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" dark @click="showNuevoClienteDialog = true">
                Agregar
                <v-icon right>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-toolbar>
            <v-layout class="py-3 d-flex">
              <v-row>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field
                    v-model="buscarTabla"
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar en la tabla..."
                    class="mx-4 flex-shrink-0 flex-grow-1"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-layout>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon color="teal" @click="abrirEditClienteDialog(item)">
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
          <template
            v-slot:item.precio10k="{ item }"
          >{{item.precio10k ? "S/ " + item.precio10k.toFixed(2) : "-"}}</template>
          <template
            v-slot:item.precio45k="{ item }"
          >{{item.precio45k ? "S/ " + item.precio45k.toFixed(2) : "-"}}</template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data: () => ({
    buscarTabla: '',
    trabajadores: [],
    headers: [
      { text: 'ID', value: 'idCliente', align: 'center' },
      { text: 'Cliente', value: 'nombreCliente', align: 'center' },
      { text: 'Dirección', value: 'direccion', align: 'center' },
      {
        text: 'Contacto',
        value: 'contacto',
        align: 'center'
      },
      { text: 'Precio 10k', value: 'precio10k', align: 'center' },
      { text: 'Precio 45k', value: 'precio45k', align: 'center' },
      { text: 'Acciones', value: 'actions', align: 'left', sortable: false }
    ],
    infoSnackbar: false,
    showNuevoClienteDialog: false,
    nuevoClienteEmpty: {
      nombreCliente: '',
      direccion: '',
      contacto: '',
      precio10k: 0,
      precio45k: 0
    },
    nuevoClienteModel: {
      nombreCliente: '',
      direccion: '',
      contacto: '',
      precio10k: 0,
      precio45k: 0
    },
    showEditarClienteDialog: false,
    clienteSeleccionado: '',
    clienteSeleccionadoModel: {
      nombreCliente: '',
      direccion: '',
      contacto: '',
      precio10k: 0,
      precio45k: 0
    },

    infoSnackbar: false,
    infoSnackbarMessage: ''
  }),

  methods: {
    formatDateTable(date) {
      let parsedDate = new Date(parseInt(date))
      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
      return `${parsedDate.toLocaleDateString(
        'es-PE',
        options
      )} ${parsedDate.toLocaleTimeString()}`
    },

    cleanSource(source) {
      return JSON.parse(JSON.stringify(source))
    },

    async actualizarTabla() {
      await this.fetchListaProductosClientes()
    },

    async crearCliente() {
      let response = await this.postNuevoCliente(this.nuevoClienteModel)
      if (response) {
        this.showNuevoClienteDialog = false
        this.nuevoClienteModel = this.nuevoClienteEmpty
        await this.actualizarTabla()
      }
    },

    abrirEditClienteDialog(item) {
      this.clienteSeleccionado = item.idCliente
      this.clienteSeleccionadoModel.nombreCliente = item.nombreCliente
      this.clienteSeleccionadoModel.direccion = item.direccion
      this.clienteSeleccionadoModel.contacto = item.contacto
      this.clienteSeleccionadoModel.precio10k = item.precio10k
        ? item.precio10k
        : 0
      this.clienteSeleccionadoModel.precio45k = item.precio45k
        ? item.precio45k
        : 0
      this.showEditarClienteDialog = true
    },

    async editarCliente() {
      let response = await this.putEditarCliente({
        idCliente: this.clienteSeleccionado,
        cliente: this.clienteSeleccionadoModel
      })
      if (response) {
        this.showEditarClienteDialog = false
        this.clienteSeleccionadoModel = this.nuevoClienteEmpty
        await this.actualizarTabla()
      }
    },

    ...mapActions('user', [
      'fetchListaProductosClientes',
      'postNuevoCliente',
      'putEditarCliente'
    ])
  },

  computed: {
    ...mapGetters('user', [
      'isLoading',
      'isError',
      'getError',
      'getListaClientes'
    ])
  },

  mounted: async function() {
    await this.actualizarTabla()
  }
}
</script>

<style>
</style>